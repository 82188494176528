import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Order} from "../../../globalModels/Order";
import {Product} from "../../../globalModels/Product";
import {User} from "../../../globalModels/User";
import {DISPOSAL_PRODUCT_TYPE, DISPOSAL_SUBTYPES, WAREHOUSE_STORAGE_DOCUMENT_TYPES} from "@/staticData/staticVariables";
import _ from "lodash";

export function DisposalOrders() {

  this.checkValid = checkValid

  this.data = {
    Order: new Order(),
    productArray: [new Product()],
    User: new User(),
    comment: '',
    price: '',
    inventoryType: '',
    skladType: '',
  }

  this.validation = {
    comment: false,
    price: false,
  }

  this.validationTranslate = {
    comment: '',
    price: '',
  }

  this.validationTxt = {
    comment: false,
    price: false,
  }

  /**
   * Getters
   */
  this.getComment = () => {
    return this.data.comment;
  }
  this.getPrice = () => {
    return this.data.price;
  }
  this.getInventoryType = () => {
    return this.data.inventoryType;
  }
  this.getSkladType = () => {
    return this.data.skladType;
  }

  /**
   * Setters
   */
  this.setComment = (val) => {
    this.data.comment = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
  this.setInventoryType = (val) => {
    this.data.inventoryType = val
  }
  this.setSkladType = (val) => {
    this.data.skladType = val
  }
}

/**
 * Global Setters
 */

DisposalOrders.prototype.setItem = function (val) {

  this.setComment(val.comment ? val.comment : '');
  this.setPrice(val.costs ? val.costs : '');

  this.data.Order.setWarehouse(val['warehouse_storage'])
  this.setInventoryType(_.find(DISPOSAL_SUBTYPES, {value: val.inventory_type}) || '')
  this.setSkladType(_.find(DISPOSAL_PRODUCT_TYPE, {value: val.sklad_type}) || '')

  this.data.Order.setId(val.id)
  this.data.Order.setStatus(val.status)

  this.data.User.setUser(val.user)

  val.lines.map((productInstance, index) => {
    if (index > 0) {
      this.addProduct()
    }
    this.data.productArray[index].setProduct(productInstance.product);
    this.data.productArray[index].setProductCount(productInstance.quantity);
  })

}

DisposalOrders.prototype.removeProduct = function (index) {
  this.data.productArray.splice(index, 1)
  if (this.data.productArray.length === 0)
    this.addProduct()
}

DisposalOrders.prototype.addProduct = function () {
  this.data.productArray.push(new Product())
}

/**
 * Validations
 */

DisposalOrders.prototype.firstValidation = function (isAdmin = false) {
  let validationItems = {
    comment: this.getComment(),
    price: this.getPrice(),
  }
  console.log(isAdmin);
  let validationOptions = {
    comment: {type: []},
    // price: {type: isAdmin ? ['not-zero'] : []}
  }

  let valid = true
  this.data.productArray.map(product => {
    if (!product.productPartialValidate({productObject: true, count: true}))
      valid = false

    let damageProductQuantity = _.find(product.product.productObject?.items_in_storage, {damage: true})?.quantity
    if (product.product.count > damageProductQuantity) {
      valid = false
      product.validation.count = true
      product.validationTranslate.count = 'disposal_moreCountThenQuantity'
    } else {
      product.validationTranslate.count = 'common_MoreThanZero'
    }
  })

  if(!this.data.User.userValidation({userId: true})){
    return false
  }

  return this.checkValid(validationItems, validationOptions) && valid
}


/**
 * Prepare Data
 */

DisposalOrders.prototype.prepareSave = function (draft, edit, complete) {

  let itemData = {
    "storage_id": this.data.Order.getWarehouse()?.id || null,
    "inventory_type": this.getInventoryType()?.value || null,
    "sklad_type": this.getSkladType()?.value || null,
    "type_id": complete ? WAREHOUSE_STORAGE_DOCUMENT_TYPES.TYPE_DISPOSAL : WAREHOUSE_STORAGE_DOCUMENT_TYPES.TYPE_DISPOSAL_REQUEST,
    "user_id": this.data.User.getUserId(),
    "status": draft ? 'draft' : complete ? 'completed' : 'in_progress',
    "comment": this.getComment(),
    "costs": this.getPrice(),
    // "requested_date": this.data.requestedDate,
    "products": [],
  }

  // if this disposal will be completed
  if(this.data.Order.getStatus() === 'completed') {
    itemData['status'] = 'completed'
  }

  let cells = []

  this.data.productArray.map(product => {

    // let product = item.data.Product.product
    let productId = product.product?.id

    if (!productId) return

    // let userProductCellArray = item.data.UserProductCellArray,
    //   userMixedProductCellArray = item.data.UserMixedProductCellArray

    // cells = [...cells, ...this.getCellsFromArray(userProductCellArray, productId, true)]
    // cells = [...cells, ...this.getCellsFromArray(userMixedProductCellArray, productId, true)]

    cells.push({
      "product_id": productId,
      "cell_id": null,
      "quantity": parseInt(product.product.count),
      "damage": false,
    })

    // if(cells.length > 0) {
    //   itemData["products"].push(cells)
    // }
  })

  itemData["products"] = cells

  return itemData

}
